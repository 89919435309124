import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

export default {

  properties: {

    billingContact: {
      type: 'object',
      properties: {
        address: {
          ...queryBuilder.properties({
            salutation: { type: 'number', required: true },
            firstName: { type: 'string', required: true },
            lastName: { type: 'string', required: true },
            street: { type: 'string', required: true },
            houseNumber: { type: 'string', required: true },
            postalCode: { type: 'string', required: true },
            city: { type: 'string', required: true },
            eMail: { type: ['string', 'null'], pattern: validationPatterns.email },
          }),
        },
      },
    },
  },
} as IJSONSchema;
