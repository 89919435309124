import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';

export default {
  ...queryBuilder.properties({
    orderBasket: {
      type: 'object',
      properties: {
        paymentMethod: { type: 'number', required: true },
      },
    },
  }),
} as IJSONSchema;
